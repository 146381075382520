import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import 'moment/locale/zh-cn';
import './index.less';
import * as serviceWorker from './serviceWorker';
import asyncComponent from './components/async-component';

const placeholder = <span style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', alignItems: 'center', 'justifyContent': 'center' }}>loading……</span>

const PageLogin = asyncComponent(() => import('./pages/login'), placeholder);
const PageData = asyncComponent(() => import('./pages/data'), placeholder);
const PageHome = asyncComponent(() => import('./pages/home'), placeholder);
const PageEquimpment = asyncComponent(() => import('./pages/equimpment'), placeholder);
const PageUpkeep = asyncComponent(() => import('./pages/upkeep'), placeholder);
const PageAdmin = asyncComponent(() => import('./pages/admin'), placeholder);
const PageSystemLogs = asyncComponent(() => import('./pages/systemLogs'), placeholder);
const PagePermission = asyncComponent(() => import('./pages/permission'), placeholder);
const PageRole = asyncComponent(() => import('./pages/role'), placeholder);
const PageMap = asyncComponent(() => import('./pages/map'), placeholder);
const PageError = asyncComponent(() => import('./pages/error'), placeholder);
const PageUser = asyncComponent(() => import('./pages/user'), placeholder);

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/"><PageHome /></Route>
      <Route exact path="/equimpment/list"><PageEquimpment /></Route>
      <Route exact path="/upkeep/list"><PageUpkeep /></Route>
      <Route exact path="/error/list"><PageError /></Route>
      <Route exact path="/data/list"><PageData /></Route>
      <Route exact path="/login"><PageLogin /></Route>
      <Route exact path="/admin/list"><PageAdmin /></Route>
      <Route exact path="/user/list"><PageUser /></Route>
      <Route exact path="/logs/list"><PageSystemLogs /></Route>
      <Route exact path="/permission/list"><PagePermission /></Route>
      <Route exact path="/role/list"><PageRole /></Route>
      <Route exact path="/map/list"><PageMap /></Route>
      <Route render={() => (<Redirect to="/result/404" />)} />
    </Switch>
  </Router>
);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
